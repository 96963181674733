
.owl-carousel {
  .owl-wrapper:after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  display: none;
  position: relative;
  -ms-touch-action: pan-y;
  margin: 0 -15px;
  padding: 0 45px;
  &[data-nav="false"] {
    padding: 0 !important;
    .owl-buttons {
      display: none !important;
    }
  }
  &[data-pagination="false"] .owl-pagination {
    display: none !important;
  }
  &.owl-slider {
    margin: 0;
    padding: 0;
    .owl-controls .owl-buttons div {
      &.owl-next {
        right: 30px;
      }
      &.owl-prev {
        left: 30px;
      }
    }
    &[data-nav="top-right"] .owl-buttons div {
      top: 20px;
      margin: 0;
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 15px;
      &.owl-next {
        right: 15px;
      }
      &.owl-prev {
        left: auto;
        right: 50px;
      }
    }
    .owl-item {
      padding: 0;
    }
  }
  .owl-wrapper {
    display: none;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;
    &.autoHeight {
      -webkit-transition: height 500ms ease-in-out;
      -moz-transition: height 500ms ease-in-out;
      -o-transition: height 500ms ease-in-out;
      -ms-transition: height 500ms ease-in-out;
      transition: height 500ms ease-in-out;
    }
  }
  .owl-item {
    float: left;
    padding: 0 15px;
    &.loading {
      min-height: 150px;
      background: url("AjaxLoader.gif") no-repeat center center;
    }
    .owl-caption {
      position: absolute;
      z-index: 99;
      background: rgba(0, 0, 0, 0.5);
      padding: 10px 15px;
      color: #fff;
      width: 50%;
    }
  }
}

.top-area .owl-carousel-area .owl-item {
  height: 550px;
}

.special-area .owl-carousel-area .owl-item {
  height: 550px;
}

[data-inner-pagination="true"] .owl-controls .owl-pagination {
  margin: 0;
  position: absolute;
  bottom: 30px;
  width: 100%;
}

[data-white-pagination="true"] .owl-controls .owl-pagination .owl-page span {
  background: #fff;
}

.owl-controls {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.01);
  text-align: center;
  .owl-pagination {
    margin-top: 10px;
    display: none;
  }
  .owl-page, .owl-buttons div {
    cursor: pointer;
    color: #fff;
    display: inline-block;
    zoom: 1;
    *display: inline;
    margin: 5px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  .owl-page:hover, .owl-buttons div:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    text-decoration: none;
  }
  .owl-page {
    display: inline-block;
    zoom: 1;
    *display: inline;
    span {
      display: block;
      width: 12px;
      height: 12px;
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity = 50);
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background: #ed8323;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -o-transition: 0.3s;
      -ms-transition: 0.3s;
      transition: 0.3s;
    }
    &.active span {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
  }
  &.clickable .owl-page:hover span {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  span.owl-numbers {
    height: auto;
    width: auto;
    color: #fff;
    padding: 2px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
  }
  .owl-buttons div {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff;
    margin: -30px 0 0 0;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
    font-size: 17px;
    &:hover {
      background: #ed8323;
      -webkit-box-shadow: 0 0 0 1px #ed8323;
      box-shadow: 0 0 0 1px #ed8323;
    }
    &:before {
      font-family: 'FontAwesome';
    }
    &.owl-next {
      right: 0;
      &:before {
        content: '\f105';
      }
    }
    &.owl-prev {
      left: 0;
      &:before {
        content: '\f104';
      }
    }
  }
}

@media (max-width: 992px) {
  .owl-controls .owl-pagination {
    display: none;
  }
}

.grabbing {
  cursor: url("../img/grabbing.png") 8 8, move;
}

.owl-carousel {
  .owl-wrapper, .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.owl-origin {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -ms-perspective: 1200px;
  perspective: 1200px;
  perspective-x: 50%;
  perspective-y: 50%;
}

.owl-fade-out {
  z-index: 10;
  -webkit-animation: fadeOut 0.7s both ease;
  -moz-animation: fadeOut 0.7s both ease;
  -o-animation: fadeOut 0.7s both ease;
  -ms-animation: fadeOut 0.7s both ease;
  animation: fadeOut 0.7s both ease;
}

.owl-fade-in {
  -webkit-animation: fadeIn 0.7s both ease;
  -moz-animation: fadeIn 0.7s both ease;
  -o-animation: fadeIn 0.7s both ease;
  -ms-animation: fadeIn 0.7s both ease;
  animation: fadeIn 0.7s both ease;
}

.owl-backSlide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  -o-animation: backSlideOut 1s both ease;
  -ms-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease;
}

.owl-backSlide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  -o-animation: backSlideIn 1s both ease;
  -ms-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease;
}

.owl-goDown-out {
  -webkit-animation: scaleToFade 0.7s ease both;
  -moz-animation: scaleToFade 0.7s ease both;
  -o-animation: scaleToFade 0.7s ease both;
  -ms-animation: scaleToFade 0.7s ease both;
  animation: scaleToFade 0.7s ease both;
}

.owl-goDown-in {
  -webkit-animation: goDown 0.6s ease both;
  -moz-animation: goDown 0.6s ease both;
  -o-animation: goDown 0.6s ease both;
  -ms-animation: goDown 0.6s ease both;
  animation: goDown 0.6s ease both;
}

.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom 0.5s ease both;
  -moz-animation: scaleUpFrom 0.5s ease both;
  -o-animation: scaleUpFrom 0.5s ease both;
  -ms-animation: scaleUpFrom 0.5s ease both;
  animation: scaleUpFrom 0.5s ease both;
}

.owl-fadeUp-out {
  -webkit-animation: scaleUpTo 0.5s ease both;
  -moz-animation: scaleUpTo 0.5s ease both;
  -o-animation: scaleUpTo 0.5s ease both;
  -ms-animation: scaleUpTo 0.5s ease both;
  animation: scaleUpTo 0.5s ease both;
}

.owl-cap-title {
  line-height: 1em;
  font-size: 120px;
  display: table;
  margin: 10px auto;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
}

@media (max-width: 992px) {
  .owl-cap-title {
    font-size: 60px;
  }
}

.owl-cap-price {
  margin-bottom: 15px;
  small {
    font-size: 20px;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity = 80);
    display: block;
  }
  h5 {
    font-size: 50px;
    color: #ef8f39;
    line-height: 1em;
    margin: 0;
  }
}

.owl-cap-weather {
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity = 80);
  .im {
    font-size: 60px;
  }
  span {
    font-size: 25px;
    position: relative;
    top: -10px;
    margin-right: 15px;
    &:after {
      content: '';
      height: 7px;
      width: 7px;
      position: absolute;
      top: 3px;
      right: -7px;
      border: 2px solid #fff;
      -webkit-border-radius: 50%;
      border-radius: 50%;
    }
  }
}

/* CSS3 Transitions */

.owl-origin {
  -webkit-perspective: 1200px;
  -webkit-perspective-origin-x : 50%;
  -webkit-perspective-origin-y : 50%;
  -moz-perspective : 1200px;
  -moz-perspective-origin-x : 50%;
  -moz-perspective-origin-y : 50%;
  perspective : 1200px;
}
/* fade */
.owl-fade-out {
  z-index: 10;
  -webkit-animation: fadeOut .7s both ease;
  -moz-animation: fadeOut .7s both ease;
  animation: fadeOut .7s both ease;
}
.owl-fade-in {
  -webkit-animation: empty 1ms both ease;
  -moz-animation: empty 1ms both ease;
  animation: empty 1ms both ease;
}
/* backSlide */
.owl-backSlide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease;
}
.owl-backSlide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease;
}

/* goDown */
.owl-goDown-out {
  -webkit-animation: scaleToFade .7s ease both;
  -moz-animation: scaleToFade .7s ease both;
  animation: scaleToFade .7s ease both;
}
.owl-goDown-in {
  -webkit-animation: goDown .6s ease both;
  -moz-animation: goDown .6s ease both;
  animation: goDown .6s ease both;
}
/* scaleUp */
.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom .5s ease both;
  -moz-animation: scaleUpFrom .5s ease both;
  animation: scaleUpFrom .5s ease both;
}

.owl-fadeUp-out {
  -webkit-animation: scaleUpTo .5s ease both;
  -moz-animation: scaleUpTo .5s ease both;
  animation: scaleUpTo .5s ease both;
}
/* Keyframes */
/*empty*/
@-webkit-keyframes empty {
  0% {opacity: 1}
}
@-moz-keyframes empty {
  0% {opacity: 1}
}
@keyframes empty {
  0% {opacity: 1}
}
@-webkit-keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}
@-moz-keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}
@keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}
@-webkit-keyframes backSlideOut {
  25% { opacity: .5; -webkit-transform: translateZ(-500px); }
  75% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
}
@-moz-keyframes backSlideOut {
  25% { opacity: .5; -moz-transform: translateZ(-500px); }
  75% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
}
@keyframes backSlideOut {
  25% { opacity: .5; transform: translateZ(-500px); }
  75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
}
@-webkit-keyframes backSlideIn {
  0%, 25% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; -webkit-transform: translateZ(-500px); }
  100% { opacity: 1; -webkit-transform: translateZ(0) translateX(0); }
}
@-moz-keyframes backSlideIn {
  0%, 25% { opacity: .5; -moz-transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; -moz-transform: translateZ(-500px); }
  100% { opacity: 1; -moz-transform: translateZ(0) translateX(0); }
}
@keyframes backSlideIn {
  0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; transform: translateZ(-500px); }
  100% { opacity: 1; transform: translateZ(0) translateX(0); }
}

@-webkit-keyframes scaleToFade {
  to { opacity: 0; -webkit-transform: scale(.8); }
}
@-moz-keyframes scaleToFade {
  to { opacity: 0; -moz-transform: scale(.8); }
}
@keyframes scaleToFade {
  to { opacity: 0; transform: scale(.8); }
}
@-webkit-keyframes goDown {
  from { -webkit-transform: translateY(-100%); }
}
@-moz-keyframes goDown {
  from { -moz-transform: translateY(-100%); }
}
@keyframes goDown {
  from { transform: translateY(-100%); }
}

@-webkit-keyframes scaleUpFrom {
  from { opacity: 0; -webkit-transform: scale(1.5); }
}
@-moz-keyframes scaleUpFrom {
  from { opacity: 0; -moz-transform: scale(1.5); }
}
@keyframes scaleUpFrom {
  from { opacity: 0; transform: scale(1.5); }
}

@-webkit-keyframes scaleUpTo {
  to { opacity: 0; -webkit-transform: scale(1.5); }
}
@-moz-keyframes scaleUpTo {
  to { opacity: 0; -moz-transform: scale(1.5); }
}
@keyframes scaleUpTo {
  to { opacity: 0; transform: scale(1.5); }
}