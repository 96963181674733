
.btn {
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
  transition: 0.2s;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  //&:hover {
  //  -webkit-border-radius: 10px;
  //  border-radius: 10px;
  //}
  &:active, &:focus {
    background-color: $default-theme-color;
  }
  > {
    .fa, .im {
      margin: 0 5px;
    }
  }
}

.btn-primary {
  background: #ed8323;
  border-color: #e27513;
  &:hover {
    background: #e27513;
    border-color: #c96810;
  }
}

.btn-primary-invert {
  background: #127cdc;
  border-color: #1070c6;
  color: #fff;
  &:hover {
    color: #fff;
    background: #1070c6;
    border-color: #0e63b0;
  }
}

.btn-paypal {
  background: #0079c1;
  color: #fff;
  border-color: #006dae;
  &:hover {
    color: #fff;
    background: #006dae;
    border-color: #00619a;
  }
}

.btn-ghost {
  background: none;
  &.btn-primary {
    color: #ed8323;
    &:hover {
      background: #ed8323;
    }
  }
  &.btn-success {
    color: #5cb85c;
    &:hover {
      background: #5cb85c;
    }
  }
  &.btn-info {
    color: #5bc0de;
    &:hover {
      background: #5bc0de;
    }
  }
  &.btn-warning {
    color: #f0ad4e;
    &:hover {
      background: #f0ad4e;
    }
  }
  &.btn-danger {
    color: #d9534f;
    &:hover {
      background: #d9534f;
    }
  }
  &.btn-primary-invert {
    color: #127cdc;
    &:hover {
      background: #127cdc;
    }
  }
  &:hover {
    color: #fff;
  }
  &.btn-default:hover {
    color: #454545;
  }
  &.btn-white {
    border-color: #fff;
    color: #fff;
    &:hover {
      background: #fff;
      color: #737373;
    }
  }
}

.row {
  &.row-wrap > [class^="col-"], &.row-col-gap > [class^="col-"] {
    margin-bottom: 30px;
  }
  &.row-full {
    margin-left: 0;
    margin-right: 0;
  }
  &.row-no-gutter, &[data-gutter="0"] {
    margin: 0 !important;
  }
  &.row-no-gutter > [class^="col-"], &[data-gutter="0"] > [class^="col-"] {
    padding: 0 !important;
  }
  &[data-gutter="10"] {
    margin-left: -5px;
    margin-right: -5px;
    > [class^="col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  &[data-gutter="60"] {
    margin-left: -30px;
    margin-right: -30px;
    > [class^="col-"] {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  &[data-gutter="120"] {
    margin-left: -60px;
    margin-right: -60px;
    > [class^="col-"] {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
}

label {
  font-weight: 400;
  display: block;
}

.form-group {
  position: relative;
  &.form-group-ghost {
    ::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
    :-moz-placeholder, ::-moz-placeholder {
      color: rgba(255, 255, 255, 0.5);
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    :-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
    label, .input-icon {
      color: #fff;
    }
    &.form-group-focus .input-icon-hightlight {
      color: #f19c4f !important;
    }
    .form-control {
      background: rgba(255, 255, 255, 0.1);
      border-color: #fff;
      color: #fff;
      &:hover {
        cursor: pointer;
      }
      &:active, &:focus {
        border-color: #f19c4f;
      }
    }
  }
  &.form-group-lg {
    //margin-bottom: 10px;
    .input-icon {
      width: 45px;
      height: 45px;
      line-height: 45px;
      font-size: 22px;
    }
    &.form-group-icon-left .form-control {
      padding-left: 45px;
    }
    &.form-group-icon-right .form-control {
      padding-right: 45px;
    }
    label {
      font-size: 16px;
      margin-bottom: 7px;
    }
    .form-control {
      height: 45px;
      padding: 10px 18px;
      font-size: 13px;
    }
  }
  &.form-group-sm {
    margin-bottom: 10px;
    label {
      margin-bottom: 3px;
      font-size: 13px;
    }
    .form-control {
      height: 25px;
      padding: 3px 7px;
      font-size: 12px;
      line-height: 1.4em;
    }
  }
  &.form-group-icon-left .form-control {
    padding-left: 32px;
  }
  &.form-group-icon-right .form-control {
    padding-right: 32px;
  }
  .input-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    line-height: 45px;
    display: block;
    top: 29px;
    left: 1px;
    text-align: center;
    color: #b3b3b3;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
    z-index: 2;
    &.no-label {
      top:0 !important;
    }
    &.input-icon-show {
      -webkit-transform: translate3d(0, -10px, 0);
      -moz-transform: translate3d(0, -10px, 0);
      -o-transform: translate3d(0, -10px, 0);
      -ms-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
      + label + .form-control {
        padding: 6px 12px;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -o-transition: 0.3s;
        -ms-transition: 0.3s;
        transition: 0.3s;
      }
    }
  }
  &.form-group-icon-right .input-icon {
    right: 1px;
    left: auto;
  }
  &.form-group-focus .input-icon {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
    &.input-icon-hightlight {
      color: #ed8323;
    }
    &.input-icon-show {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
      -ms-filter: none;
      filter: none;
      + label + .form-control {
        padding-left: 32px;
      }
    }
    &.input-icon-bounce {
      -webkit-animation: 1s bounce;
      -moz-animation: 1s bounce;
      -o-animation: 1s bounce;
      -ms-animation: 1s bounce;
      animation: 1s bounce;
    }
    &.input-icon-swing {
      -webkit-animation: 1s swing;
      -moz-animation: 1s swing;
      -o-animation: 1s swing;
      -ms-animation: 1s swing;
      animation: 1s swing;
    }
    &.input-icon-tada {
      -webkit-animation: 1s tada;
      -moz-animation: 1s tada;
      -o-animation: 1s tada;
      -ms-animation: 1s tada;
      animation: 1s tada;
    }
    &.input-icon-shake {
      -webkit-animation: 1s shake;
      -moz-animation: 1s shake;
      -o-animation: 1s shake;
      -ms-animation: 1s shake;
      animation: 1s shake;
    }
  }
  &.form-group-filled {
    .input-icon-show {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
      -ms-filter: none;
      filter: none;
      + label + .form-control {
        padding-left: 32px;
      }
    }
    label {
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -o-transition: 0.3s;
      -ms-transition: 0.3s;
      transition: 0.3s;
      &.label-anim {
        color: #ed8323;
        -webkit-animation: label-anim 0.5s;
        -moz-animation: label-anim 0.5s;
        -o-animation: label-anim 0.5s;
        -ms-animation: label-anim 0.5s;
        animation: label-anim 0.5s;
      }
      &.label-highlight {
        color: #ed8323;
      }
    }
  }
}

.form-control {
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 1.6em;
  height: 45px;
  @include border-radius(5px);
  border-color: darken($gray, 10%) !important;
  &:active, &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ed8323;
  }
}

.help-block {
  font-size: 12px;
  margin-top: 7px;
}

.checkbox, .radio {
  margin-bottom: 15px;
  margin-top: 0;
}

.checkbox-inline label {
  cursor: pointer;
}

.radio-inline {
  label {
    cursor: pointer;
  }
  + .radio-inline {
    margin-left: 15px;
  }
}

.checkbox-inline + .checkbox-inline {
  margin-left: 15px;
}

label.label-focus {
  color: #ed8323;
  -webkit-animation: label-anim 0.5s;
  -moz-animation: label-anim 0.5s;
  -o-animation: label-anim 0.5s;
  -ms-animation: label-anim 0.5s;
  animation: label-anim 0.5s;
}

.btn-group-select-num > .btn {
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
  height: 28px;
  line-height: 26px;
  width: 28px;
  padding: 0;
  background: none;
  color: #737373;
  border: 1px solid transparent;
  &:hover {
    color: #737373;
    border-color: #737373;
    background: none;
  }
  &.active {
    background: #ed8323;
    border-color: #e27513;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    &:hover {
      background: #ed8323;
      border-color: #e27513;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #fff;
    }
  }
}

.form-group-lg .btn-group-select-num {
  margin-top: 3px;
  > .btn {
    height: 35px;
    line-height: 35px;
    width: 35px;
  }
}


@-moz-keyframes label-anim {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@-webkit-keyframes label-anim {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@-o-keyframes label-anim {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@-ms-keyframes label-anim {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@keyframes label-anim {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}