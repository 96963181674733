
.countdown {
  width: 400px;
  overflow: hidden;
  height: 58px;
  margin: 20px 0;
  display: table;
  > div {
    display: table-cell;
    > span {
      display: block;
      text-align: center;
    }
  }
}

span.count {
  font-size: 48px;
  line-height: 48px;
}

.countdown.countdown-inline {
  width: 100%;
  margin: 10px 0 0 0;
  height: auto;
  > div {
    display: inline;
    &:first-child span {
      &.count {
        font-size: 25px;
        font-weight: bold;
        margin-right: 5px;
        color: #ed8323;
      }
      &.title {
        font-size: 20px;
        font-weight: bold;
        display: inline;
        margin-right: 10px;
        color: #ed8323;
      }
      &.count:after {
        content: '';
        margin: 0;
      }
    }
    &:last-child span.count:after {
      content: '';
      margin: 0;
    }
    > span {
      display: inline;
      line-height: 1em;
    }
    span {
      &.count {
        font-size: 20px;
        &:after {
          content: ':';
          margin: 0 2px;
        }
      }
      &.title {
        display: none;
      }
    }
  }
}

.countdown-lg {
  margin: 20px auto;
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  span.count {
    font-size: 70px;
    margin-bottom: 10px;
  }
  > div {
    padding: 0 25px;
  }
  .title {
    color: rgba(255, 255, 255, 0.7);
  }
}