footer#main-footer {
  @include linear-gradient(135deg, $footer-bg-color, darken($footer-bg-color, 10%));
  padding: 0;
  color: #e6e6e6;
  font-size: 11px;
  line-height: 1.4em;
  .logo {
    //margin-bottom: 15px;
    img {
      width: 100% !important;
    }
    @media (max-width: 991px) {
      img {
        width: 300px !important;
      }
    }
  }
  a, h1, h2, h3, h4, h5,p {
    color: darken($gray, 15%);
  }
  .form-control {
    background: #121212;
    border-color: #000;
    color: #fff;
    &:focus {
      border-color: #ed8323;
    }
  }
  .footer-bottom {
    padding: 20px 0 10px 0;
    background-color: $footer-bottom-bg-color;
    position: relative;
    &:after {
      content: " ";
      position: absolute;
      width: 50%;
      border-top: 30px solid transparent;
      border-right: 25vw solid darken($default-theme-color, 20%);
      top:-30px;
      right: 0;
    }
    @media (min-width: 991px) {
      [class*="col"] {
        padding: 0;
      }
    }
    p {
      font-size: 10px;
      line-height: 16px;
      color: $darkgray !important;
    }
  }
  .social li a {
    font-size: 1.2em;
  }
}

.list-footer > li {
  margin-bottom: 5px;
  a {
    position: relative;
    padding-left: 15px;
    &:before {
      content: "\f054";
      font-family: "FontAwesome", sans-serif;
      position: absolute;
      width: 30px;
      height: 30px;
      left:0;
      top:2px;
      color: $default-theme-color;
    }
    @include transition(all .2s);
  }
  > a:hover {
    text-decoration: none;
    color: $default-theme-color !important;
  }
}