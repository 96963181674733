.card-select {
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    overflow: hidden;
    padding: 10px 15px;
    border: 1px solid #ccc;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin-bottom: 15px;
    cursor: pointer;
    width: 90%;
    &.card-item-selected {
      border-color: #ed8323;
      .card-select-cvc {
        display: inline-block;
      }
    }
  }
  .card-select-img {
    width: 64px;
    float: left;
    margin-right: 15px;
  }
  .card-select-data {
    display: table;
  }
  .card-select-cvc {
    display: none;
    width: 60px;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
  }
  .card-select-number {
    font-size: 14px;
    display: inline-block;
    margin-right: 20px;
    line-height: 40px;
    margin-bottom: 0;
  }
}