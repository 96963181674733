
.header-top {
  padding: 0;
  //background-image: url("../img/patterns/carbon.png");
  //background-size: 0.3%;
  //background-repeat: repeat;
  background-color: $top-bg;
  height: 40px;
}

@media (max-width: 992px) {
  .header-top {
    max-height: none;
  }
}

.logo {
  display: block;
  img {
    width: auto;
    height: 120px;
  }
}