.spinner-clock {
  width: 150px;
  height: 150px;
  border: 4px solid #fff;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  margin: 0 auto 20px auto;
  &:before {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: '';
    margin: -6px 0 0 -6px;
    z-index: 1;
  }
  .spinner-clock-hour {
    -webkit-animation-name: spinner;
    -moz-animation-name: spinner;
    -o-animation-name: spinner;
    -ms-animation-name: spinner;
    animation-name: spinner;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    animation-timing-function: linear;
    width: 4px;
    background: #fff;
    margin-left: -2px;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    position: absolute;
    left: 50%;
    -webkit-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
  }
  .spinner-clock-minute {
    -webkit-animation-name: spinner;
    -moz-animation-name: spinner;
    -o-animation-name: spinner;
    -ms-animation-name: spinner;
    animation-name: spinner;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    animation-timing-function: linear;
    width: 4px;
    background: #fff;
    margin-left: -2px;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    position: absolute;
    left: 50%;
    -webkit-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    -ms-animation-duration: 1s;
    animation-duration: 1s;
    top: 15px;
    height: 56px;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity = 80);
  }
  .spinner-clock-hour {
    -webkit-animation-duration: 12s;
    -moz-animation-duration: 12s;
    -o-animation-duration: 12s;
    -ms-animation-duration: 12s;
    animation-duration: 12s;
    top: 31px;
    height: 40px;
  }
  &.spinner-clock-slow {
    .spinner-clock-minute {
      -webkit-animation-duration: 3px;
      -moz-animation-duration: 3px;
      -o-animation-duration: 3px;
      -ms-animation-duration: 3px;
      animation-duration: 3px;
    }
    .spinner-clock-hour {
      -webkit-animation-duration: 36s;
      -moz-animation-duration: 36s;
      -o-animation-duration: 36s;
      -ms-animation-duration: 36s;
      animation-duration: 36s;
    }
  }
}