.footer-links {
  list-style: none;
  font-size: 10px;
  text-align: center;
  position: absolute;
  bottom: 50px;
  width: 100%;
  > li {
    display: inline-block;
    margin: 0 10px;
    > a {
      opacity: 0.7;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
      filter: alpha(opacity = 70);
      color: #fff;
      &:hover {
        text-decoration: underline;
        opacity: 1;
        -ms-filter: none;
        filter: none;
      }
    }
  }
}

.footer-social {
  position: absolute;
  bottom: 50px;
  text-align: center;
  width: 100%;
  > li {
    display: inline-block;
    margin: 0 7px;
  }
}