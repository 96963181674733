.navbar {
  //padding: 5px 0;
  min-height: 0;
  margin: 0;
  .navbar-header {
    .logo {
    }
  }
}

@media (max-width: 992px) {
  .navbar-header {
    float: none;
    .logo {
      padding: 10px 0;
      margin: auto;
      text-align: center;
    }
  }
  ul.slimmenu {
    text-align: left;
  }
}

@media (min-width: 991px) {
  .navbar-header {
    position: relative;
    .logo {
      position: absolute;
      top: -40px;
      left: -20px;
      padding: 20px 0 0 0;
      z-index: 9999 !important;
      //@include box-shadow(0,2px,5px,$logo-border-color);
      //@include border-radius(5px);
      //border: 2px solid lighten($logo-border-color, 50%);
      &:before {
        content: " ";
        width: 85%;
        height: 50%;
        left: 0;
        top: 25%;
        position: absolute;
        @include box-shadow(0, 2px, 100px, rgba(0, 0, 0, 0.75));
        z-index: -1 !important;
        background-color: white;
      }
    }
  }
}

.search-form {
  background-color: rgba($white, 0.2);
  @include border-radius(5px);
  font-family: 'Roboto Condensed', sans-serif;
  //border: 2px solid rgba($gray, 0.1);
  h2 {
    font-size: 1.5em;
    padding: 15px 30px 10px 30px;
    background-color: rgba(darken(white, 65%), 0.7);
    //@include gradient-horizontal($white,$gray,0%,100%);
    font-weight: 500;
    color: $white;
    @include border-radius(5px 5px 0 0);
    border-bottom: 1px solid darken(rgba($gray, 0.25), 20%);
    //@include box-shadow(0,1px,1px,rgba($darkgray,0.5));
  }
  form {
    input, textarea, select {
      @include border-radius(5px);
      background-color: transparent;
      color: $white;
      border-width: 2px;
      font-weight: bold;
      font-size: 14px !important;
    }
    button {
      //background-color: transparent;
      color: $top-bg;
    }
    padding: 10px 40px 20px 40px;
    label {
      font-size: 14px !important;
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
    }
    .select2-selection--single {
      background-color: transparent;
      border-width: 2px;
      .select2-selection__rendered, .select2-selection__arrow {
        color: $white;
        font-weight: bold;
      }
    }
  }

  .datepicker-dropdown {
    background-color: rgba($top-bg, .8) !important;
    color: $white !important;
    a:hover, button, th:hover {
      background-color: $top-bg !important;
      color: $white !important;
    }
    span:hover {
      background-color: $darkgray !important;
      color: $white;

    }
    td.today, td.day:hover {
      background-color: $darkgray !important;
      color: $white !important;
    }
    &.dropdown-menu:after {
      border-bottom-color: rgba($top-bg, .8) !important;
    }
  }

  .bootstrap-timepicker-widget {
    background-color: rgba($top-bg, .8) !important;
    color: $white !important;
    a {
      color: $white !important;
    }
    a:hover, button, th:hover {
      background-color: $top-bg !important;
      color: $default-theme-color !important;
    }
    &.dropdown-menu:after {
      border-bottom-color: rgba($top-bg, .8) !important;
    }
  }
}

.top-user-area {
  .socials {
    li a {
      color: $top-social-link-color;
      &:hover {
        color: $top-social-link-hover;
      }
    }
  }
}

.select2-container {
  width: 100% !important;
}

.select2-selection--single {
  height: 45px !important;
  width: 100%;
  border-color: darken($gray, 10%) !important;
  .select2-selection__rendered, .select2-selection__arrow {
    line-height: 42px !important;
    height: 45px !important;
    width: 100%;
  }
}

.select2-dropdown {
  font-family: 'Roboto Condensed', sans-serif;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $default-theme-color !important;
}

.form-control, .select2-selection--single {
  @include box-shadow(0, 0, 10px, rgba($black, 0.1), true);
}

.font-theme-color {
  color: $default-theme-color;
}

.font-bold {
  font-weight: 500;
}

.font-italic {
  font-style: italic;
}

.page-title {
  padding: 20px 0 10px 0 !important;
  margin: 0 !important;
  //background-color: $default-theme-color;
  @include gradient-horizontal($default-theme-color, darken($default-theme-color, 20%));
  .title {
    color: $white;
    font-weight: 300;
    @media (min-width: 768px) {
      text-align: right;
    }
    font-size: 0.4em;
    letter-spacing: -0.03em;
  }
}

.page-content {
  padding: 20px 0;
}

.blog-posts {
  .thumb-title {
    font-size: 1em;
  }
  .thumb-desc {
    font-size: 0.75em;
  }
}

.booking-item-features.detail {
  width: 100%;
  li {
    margin-right: 25px;
    display: inline-block;
  }
  i {
    margin-right: 5px;
    font-size: 1.25em;
    display: inline-block;
  }
  span {
    line-height: 35px;
  }
}

.booking-item-price {
  font-size: 2em;
}

.booking-item-car-title {
  font-size: 1.4em;
  margin-bottom: 10px;
}

.booking-filters {
  h3 {
    font-size: 2em;
  }
}

.social-share {
  div {
    display: inline-flex;
    flex-wrap: wrap;
    margin-right: 5px;
    &:first-child {
      font-weight: 600;
    }
  }
}

.jssocials-share {
  &:first-child .jssocials-share-link {
    margin-left: 0;
  }
}

.post-share a {
  font-size: 12px !important;
}

.plain .jssocials-share-logo {
  font-size: 12px !important;
}

.plain .jssocials-share-link {
  border: 1px solid darken(white, 25%) !important;
  &:hover {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    i {
      color: $default-theme-color !important;
    }
  }
}

.post-inner {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.post-desciption {
  padding-top: 10px !important;
}

.search-form2 {
  [class*="col-lg"] {
    padding: 0 10px;
  }
}

.bg-color.line {
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    border-top: 30px solid transparent;
    border-right: 100vw solid rgba(darken($default-theme-color, 10%), .5);
    top: -30px;
    z-index: 100;
  }
  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    border-bottom: 30px solid transparent;
    border-left: 100vw solid rgba(darken($default-theme-color, 10%), .95);
    bottom: -30px;
    z-index: 0;
  }
}

.footer2 {
  position: relative;
  padding-top: 30px !important;
  background-repeat: repeat;
  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    border-bottom: 30px solid transparent;
    border-left: 100vw solid $default-theme-color;
    top: 0;
  }
  .slogan {
    margin-top: -5px;
    font-size: 10px;
    color: $default-theme-color;
  }
  .logo {
    img {
      filter: saturate(80%);
    }
  }
  .break {
    position: relative;
    border-top: 1px solid lighten($footer-bg-color, 10%);
    padding: 10px 0;
    margin-top: 10px;
    &:before {
      content: " ";
      position: absolute;
      width: 100%;
      border-top: 1px solid darken($footer-bg-color, 10%);
      top: -2px;
    }
    &:after {
      content: " ";
      top: -7px;
      left: 0;
      z-index: 100;
      position: absolute;
      background-image: url("../img/logo/logo-zb.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      width: 25px;
      height: 25px;
    }
  }
  .box-icon, [class^="box-icon-"], [class*=" box-icon-"] {
    background-color: darken($footer-bg-color, 10%);
    border: 1px solid $default-theme-color;
  }
  .social li a {
    color: $default-theme-color !important;
  }
  .footer-link-list {
    list-style-type: none;
    text-align: center;
    text-transform: uppercase;
    padding: 0 10px;
    margin-bottom: 20px;
    li {
      display: inline-block;
      a {
        padding: 5px 10px;
        height: 30px;
        display: block;
        color: $default-theme-color !important;
        @include transition(all .2s);
        &:hover {
          color: $gray !important;
        }
      }
    }
  }
  .call-center {
    font-family: "Open Sans Condensed", sans-serif;
    background-color: darken($footer-bg-color, 10%);
    border: 2px solid darken($default-theme-color, 10%);
    padding: 10px;
    width: 180px;
    text-align: center;
    margin-top: 20px;
    @include border-radius(20px 0 20px 0);
    @include transition(all 1s);
    &:hover {
      transform: scale(.9);
    }
    div {
      display: block;
      padding: 2px 0;
      color: $default-theme-color;
    }
    .title {
      font-size: 1.25em;
      font-weight: 500;
      padding-bottom: 8px;
      margin-bottom: 10px;
      letter-spacing: 0.03em;
      border-bottom: 2px solid darken($default-theme-color, 10%);
    }
    .phone {
      font-size: 1.85em;
      font-weight: 800;
      a {
        color: $default-theme-color !important;
        &:active, &:hover, &:focus {
          text-decoration: none;
        }
      }
    }
  }
  .fifteen-year {
    display: block;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    p {
      color: $default-theme-color !important;
      font-size: 11px;
      letter-spacing: 0.3em;
      margin: 0;
      padding: 0;
    }
  }
  .copyrights {
    padding-top: 10px;
  }
}

.line-tr {
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    width: 50%;
    border-top: 100px solid transparent;
    border-right: 100vw solid $gray;
    top: -100px;
    right: 0;
    z-index: 0;
  }
}

.booking-item-dates-change {
  background-color: darken(white, 5%);
  border: 1px solid darken(white, 20%) !important;
}

.top-phone {
  text-align: right;
  a {
    font-weight: 700 !important;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 1.2em;
    letter-spacing: -0.02em;
  }
}

.slogans-home {
  @include gradient-horizontal($default-theme-color, darken($default-theme-color, 20%));
  .thumb {
    //border: 1px solid $gray;
    @include box-shadow(0, 5px, 20px, rgba(darken($default-theme-color, 20%), 0.75));
    @include transition(all .5s ease-in-out);
    @include border-radius(10px);
    &:hover {
      transform: scale(1.05);
    }
    .thumb-header {
      position: relative;
      z-index: 0;
      margin-bottom: 0;
      img {
        filter: grayscale(70%);
        border: none;
        width: 100% !important;
        @include border-radius(10px 10px 0 0);
      }
      i {
        border: 2px solid $black;
        color: $black;
        @include border-radius(5px);
        &:hover {
          background-color: $black;
          color: $default-theme-color !important;
        }
      }
      h4 {
        position: absolute;
        bottom: 20px;
        left: 20px;
        font-size: 1.3em;
        z-index: 9999;
        font-weight: 500;
        text-shadow: 1px 2px 1px $black;
        letter-spacing: -0.03em;
      }
      &:after {
        content: " ";
        position: absolute;
        width: 100%;
        display: block;
        left: 0;
        bottom: 0;
        z-index: 30;
        border-top: 50px solid transparent;
        border-left: 250px solid rgba($black, 0.4);
        @include border-radius(0 0 0 0);
      }
      &:before {
        content: " ";
        position: absolute;
        width: 100%;
        display: block;
        left: 0;
        bottom: 0;
        z-index: 10;
        border-top: 60px solid transparent;
        border-left: 250px solid rgba(darken($default-theme-color, 10%), 0.7);
        @include border-radius(0 0 0 0);
      }
      h4 {
        font-size: 1.1em;
        font-weight: 700;
        text-transform: uppercase !important;
      }
    }
    .thumb-caption {
      padding: 10px 20px 15px 20px;
      min-height: 70px;
      border-top: 1px solid $default-theme-color;
      background-color: darken($default-theme-color, 25%);
      @include border-radius(0 0 10px 10px);
      position: relative;
      p {
        font-size: 13px;
        font-weight: 700;
        text-shadow: 1px 1px 1px $black;
        letter-spacing: -0.01em;
      }
      a {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $default-theme-color;
        width: 20px;
        height: 20px;
        text-align: center;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        @include transition(all .2s);
        &:hover {
          i {
            color: $white;
          }
        }
        i {
          transform: rotate(45deg);
          color: darken($default-theme-color, 25px);
        }
      }
    }
  }
}

.booking-item-features {
  > li > i {
    border-color: darken(white, 20%);
    //background-color: $top-bg;
    color: darken(white, 40%);
  }
}

.home-classes-one {
  list-style-type: none;
  display: table;
  width: 100%;
  li {
    display: table-cell;
    text-align: center;
    vertical-align: center;
    &:first-child a {
      @include border-radius(20px 0 0 20px);
      @include gradient-horizontal(darken($default-theme-color, 20%), $default-theme-color);
      &:hover {
        @include gradient-horizontal(darken($default-theme-color, 20%), darken($default-theme-color, 20%));
      }
    }
    &:last-child a {
      @include border-radius(0 20px 20px 0);
      @include gradient-horizontal($default-theme-color, darken($default-theme-color, 20%));
      &:hover {
        @include gradient-horizontal(darken($default-theme-color, 20%), darken($default-theme-color, 20%));
      }
    }
    a {
      border: 1px solid darken($default-theme-color, 20%);
      padding: 10px 20px 5px 20px;
      display: block;
      background-color: $default-theme-color;
      color: $white;
      font-size: small;
      //font-weight: 700;
      text-transform: uppercase;
      font-family: "Open Sans Condensed", sans-serif;
      @include transition(all .5s);
      span {
        font-weight: 700;
      }
      @include box-shadow(0, 5px, 0, rgba(darken($default-theme-color, 30%), 1));
      &:hover {
        background-color: darken($default-theme-color, 20%);
      }
    }
  }
}

.home-classes-two {
  padding: 0;
  div {
    padding: 0;
  }
  a {
    border-top: 1px solid darken(white, 10%);
    //border-bottom: 1px solid darken(white, 15%);
    border-right: 1px solid darken(white, 10%);
    padding: 10px 20px 7px 20px;
    display: block;
    background-color: $white;
    color: lighten($top-bg, 40%);
    font-size: small;
    text-transform: uppercase;
    font-family: "Open Sans Condensed", sans-serif;
    text-align: center;
    @include transition(all .5s);
    //@include box-shadow(0,1px,1px,rgba(0,0,0,.05));
    span {
      font-weight: 700;
    }
    &:hover {
      background-color: darken($white, 5%);
    }
  }
  div:first-child {
    a {
      @include border-radius(30px 0 0 0);
      border-left: 1px solid darken(white, 10%);
    }
  }
  div:last-child {
    a {
      @include border-radius(0 30px 0 0);
    }
  }
  @media (max-width: 991px) {
    a {
      border-left: 1px solid darken(white, 10%);;
    }
    div:first-child {
      a {
        @include border-radius(0);
      }
    }
    div:last-child {
      a {
        @include border-radius(0);
      }
    }
  }
}

.fotorama--fullscreen .fotorama__nav, .fotorama--fullscreen .fotorama__stage, .fotorama--fullscreen {
  background-color: $white !important;
}

.booking-item:hover {
  border: 1px solid $gray;
  @include box-shadow(0, 5px, 10px, rgba(0, 0, 0, 0.15));
}

.address-list {
  margin: 0 !important;
  padding: 30px 20px 10px 20px !important;
  background-color: darken(white, 2%);
  border:1px solid darken(white, 5%);
  @include border-radius(5px);
  .owl-next {
    right: 10px !important;
  }
  .owl-prev {
    left: 10px !important;
  }
  address {
    padding: 0 10px;
    strong {
      display: block;
      font-size: 1.1em;
      font-weight: 500;
      color: $default-txt-color;
      padding: 10px 20px;
      margin-bottom: 10px;
      background-color: darken($white, 5%);
      border-bottom: 1px solid darken($white, 10%);
      @include border-radius(10px);
    }
    abbr {
      margin-right: 10px;
    }
    a {
      color: $default-txt-color;
    }
    .google-map {
      height: 120px !important;
      display: block;
      border:1px solid darken($white, 10%);
      @include border-radius(5px);
    }
  }
}

.saturate {
  &.gray {
    filter: saturate(0%);
  }
  &.opacity-50 {
    opacity: 0.5;
  }
}

.r-line-lg {
  border-right: 1px solid darken($white, 7%);
}

.contact {
  input {

  }
}

.owl-controls .owl-buttons div {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &:hover {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    &.owl-prev:before, &.owl-next:before {
      color: $default-theme-color;
    }
  }
  &:before {
    color: darken($white, 25%);
    font-size: 1.25em;
  }
  &.owl-prev:before {
    content: "\f053";
  }
  &.owl-next:before {
   content: "\f054";
  }
}

.align-justify {
  text-align: justify !important;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: $default-theme-color;
}
.nav-tabs>li>a {
  color: $default-txt-color;
}

.owl-carousel .bg-img {
  filter: saturate(0%);
}

.car-prices {
  .thumbnail {
    h3 {
      font-size: 1.3em;
    }
    table {
      font-size: .85em;
      th, td {
        padding: 2px 5px;
      }
    }
    a {
      font-weight: 700;
      width: 100%;
    }
  }
}

.title-home {
  h1 {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 1px;
    color: lighten($default-theme-color, 20%);
  }
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: darkred;
  overflow: hidden;
}

#loader {
  background: url('../img/logo/logo-w.svg') no-repeat;
  width: 280px;
  height: 100px;
  position: relative;
  top: 40%;
  margin: -20px auto 0 auto;
}