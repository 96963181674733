
.post {
  margin-bottom: 45px;
  //overflow: hidden;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  .post-header {
    border-bottom: 2px solid #ed8323;
    blockquote {
      padding: 40px;
      font-size: 30px;
      padding-left: 60px;
      margin: 0;
      font-weight: 400;
      background: #f5f5f5;
      line-height: 1.4em;
      font-style: italic;
      color: #686868;
      &:before {
        top: 10px;
        left: 10px;
      }
    }
    .post-link {
      padding: 30px 15px;
      font-size: 50px;
      font-weight: bold;
      display: block;
      background: #ed8323;
      color: #fff;
      &:hover {
        background: #d66f11;
        color: #fff;
      }
    }
  }
  .post-inner {
    padding: 15px 0 45px 0;
  }
  .post-title {
    margin: 0 0 15px 0;
    font-size: 20px;
  }
  .post-desciption {
    margin-bottom: 15px;
  }
  .post-meta {
    list-style: none;
    margin: 0 0 5px 0;
    padding: 0 0 5px 0;
    border-bottom: 1px dashed #e6e6e6;
    display: table;
    li {
      display: inline-block;
      margin-right: 15px;
      a {
        font-size: 10px;
        font-style: italic;
        color: #737373;
      }
      .fa {
        margin-right: 3px;
        color: #b2b2b2;
      }
    }
  }
}