
.bg-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-darken {
  background: #f2f2f2;
}

.bg-color {
  background: #ed8323;
}

.bg-holder {
  position: relative;
  overflow: hidden;
  > {
    .bg-mask, .bg-blur, .bg-mask-darken, .bg-mask-lighten, .bg-parallax, .bg-img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      background-position: center center;
    }
    .bg-video {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: auto;
      z-index: 4;
    }
    .bg-mask, .bg-parallax, .bg-img {
      width: 100%;
      height: 100%;
    }
    .bg-mask, .bg-mask-darken, .bg-mask-lighten, .bg-mask-white, .bg-mask-color, .bg-mask-color-invert {
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity = 50);
      background: #000;
    }
    .bg-mask-lighten {
      opacity: 0.3;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
      filter: alpha(opacity = 30);
    }
    .bg-mask-darken {
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
    }
    .bg-mask-color {
      background: #ed8323;
    }
    .bg-mask-white {
      background: #fff;
    }
    .bg-mask-color-invert {
      background: #127cdc;
    }
    .bg-parallax {
      background-position: 50% 0;
      background-attachment: fixed;
    }
    .bg-blur {
      width: 50% !important;
      height: 50% !important;
      -webkit-transform-origin: 1% 1%;
      -moz-transform-origin: 1% 1%;
      -o-transform-origin: 1% 1%;
      -ms-transform-origin: 1% 1%;
      transform-origin: 1% 1%;
      -webkit-transform: scale(2.1);
      -moz-transform: scale(2.1);
      -o-transform: scale(2.1);
      -ms-transform: scale(2.1);
      transform: scale(2.1);
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      -webkit-filter: blur(1px);
      -moz-filter: blur(1px);
      -o-filter: blur(1px);
      filter: blur(1px);
      z-index: 0;
      &:before {
        content: '';
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      &.bg-parallax {
        background-attachment: scroll;
      }
    }
    .bg-holder-content, .bg-content {
      position: relative;
      z-index: 7;
    }
    .bg-front {
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;
      z-index: 6;
    }
  }
}

@media (max-width: 992px) {
  .bg-holder > .bg-parallax {
    background-attachment: scroll;
    background-position: center center;
  }
}

@media (max-width: 992px) {
  .bg-holder > .bg-front.bg-front-mob-rel {
    position: relative;
  }
}