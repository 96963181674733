
.loc-info {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  padding-top: 50px;
  color: #fff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  .loc-info-title {
    color: #fff;
    font-weight: 400;
    img {
      width: auto;
      margin-right: 10px;
      margin-top: -5px;
    }
  }
  .loc-info-weather {
    margin: 0;
  }
  .loc-info-weather-icon {
    font-size: 60px;
    margin-left: 5px;
  }
  .loc-info-weather-num {
    font-size: 30px;
    position: relative;
    top: -15px;
    .meteocon {
      margin-left: -5px;
    }
  }
  .loc-info-list {
    list-style: none;
    padding: 0;
    margin: 5px 0 10px 0;
    font-size: 13px;
    > li > a {
      color: #fff;
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
      .fa {
        margin-right: 5px;
      }
      &:hover {
        opacity: 1;
        -ms-filter: none;
        filter: none;
      }
    }
  }
  img {
    width: 300px;
    height: auto;
  }
}