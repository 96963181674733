.preview-area {
  text-align: center;
}

.preview-item {
  opacity: 0.85;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
  filter: alpha(opacity = 85);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
  &:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    .preview-img {
      -webkit-transform: translate(0, -5px) scale(1.05);
      -moz-transform: translate(0, -5px) scale(1.05);
      -o-transform: translate(0, -5px) scale(1.05);
      -ms-transform: translate(0, -5px) scale(1.05);
      transform: translate(0, -5px) scale(1.05);
    }
  }
}

.preview-img {
  display: block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

.preview-desc {
  color: #fff;
  margin: 10px 20px 20px 20px;
  font-size: 13px;
}

.preview-title {
  text-transform: uppercase;
  display: table;
  line-height: 1em;
  padding: 5px 7px;
  background: #ed8323;
  margin: 0 auto;
  > a {
    color: #fff;
  }
}

.preview-logo {
  width: auto;
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 10px;
}

.ri-grid {
  position: relative;
  height: auto;
  width: 100%;
  ul {
    list-style: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    zoom: 1;
    &:before {
      content: '';
      display: table;
    }
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    li {
      -webkit-perspective: 400px;
      -moz-perspective: 400px;
      -ms-perspective: 400px;
      perspective: 400px;
      margin: 0;
      padding: 0;
      float: left;
      position: relative;
      display: block;
      overflow: hidden;
      -webkit-transition: opacity 0.5s;
      -moz-transition: opacity 0.5s;
      -o-transition: opacity 0.5s;
      -ms-transition: opacity 0.5s;
      transition: opacity 0.5s;
      &:hover {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        filter: alpha(opacity = 50);
      }
      a {
        display: block;
        outline: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
      }
    }
  }
}