
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity = 80);
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur {
  cursor: zoom-out;
  .mfp-image-holder .mfp-close {
    cursor: zoom-out;
  }
}

.mfp-zoom {
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
  a {
    color: #ccc;
    &:hover {
      color: #fff;
    }
  }
}

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none;
}

button {
  &.mfp-close, &.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    padding: 0;
    z-index: 1046;
  }
  &::-moz-focus-inner {
    padding: 0;
    margin: 0;
  }
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity = 65);
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  &:hover, &:focus {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  &:active {
    top: 1px;
  }
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity = 65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:active {
    margin-top: -54px;
  }
  &:hover, &:focus {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  &:before, &:after, .mfp-b, .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: solid transparent;
  }
  &:after, .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
  }
  &:before, .mfp-a {
    border-top-width: 21px;
    border-bottom-width: 21px;
  }
}

.mfp-arrow-left {
  left: 0;
  &:after, .mfp-a {
    border-right: 17px solid #fff;
    margin-left: 31px;
  }
  &:before, .mfp-b {
    margin-left: 25px;
  }
}

.mfp-arrow-right {
  right: 0;
  &:after, .mfp-a {
    border-left: 17px solid #fff;
    margin-left: 39px;
  }
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
  .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
  }
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    background: #000;
  }
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

.mfp-figure {
  line-height: 0;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    background: #444;
  }
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-break: break-word;
  padding-right: 36px;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

.mfp-fade {
  &.mfp-bg {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    -ms-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    &.mfp-ready {
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
    }
    &.mfp-removing {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
  }
  &.mfp-wrap {
    .mfp-content {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
      -webkit-transition: all 0.15s ease-out;
      -moz-transition: all 0.15s ease-out;
      -o-transition: all 0.15s ease-out;
      -ms-transition: all 0.15s ease-out;
      transition: all 0.15s ease-out;
    }
    &.mfp-ready .mfp-content {
      opacity: 1;
      -ms-filter: none;
      filter: none;
    }
    &.mfp-removing .mfp-content {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
  }
}

.mfp-zoom-in {
  .mfp-with-anim {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  &.mfp-bg {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
    }
  }
  &.mfp-removing {
    .mfp-with-anim {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -o-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
    &.mfp-bg {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
  }
}

.mfp-newspaper {
  .mfp-with-anim {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0) rotate(500deg);
    -moz-transform: scale(0) rotate(500deg);
    -o-transform: scale(0) rotate(500deg);
    -ms-transform: scale(0) rotate(500deg);
    transform: scale(0) rotate(500deg);
  }
  &.mfp-bg {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
  }
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: scale(1) rotate(0deg);
      -moz-transform: scale(1) rotate(0deg);
      -o-transform: scale(1) rotate(0deg);
      -ms-transform: scale(1) rotate(0deg);
      transform: scale(1) rotate(0deg);
    }
    &.mfp-bg {
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
    }
  }
  &.mfp-removing {
    .mfp-with-anim {
      -webkit-transform: scale(0) rotate(500deg);
      -moz-transform: scale(0) rotate(500deg);
      -o-transform: scale(0) rotate(500deg);
      -ms-transform: scale(0) rotate(500deg);
      transform: scale(0) rotate(500deg);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
    &.mfp-bg {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
  }
}

.mfp-move-horizontal {
  .mfp-with-anim {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    -o-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
  }
  &.mfp-bg {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
  }
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    }
    &.mfp-bg {
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
    }
  }
  &.mfp-removing {
    .mfp-with-anim {
      -webkit-transform: translateX(50px);
      -moz-transform: translateX(50px);
      -o-transform: translateX(50px);
      -ms-transform: translateX(50px);
      transform: translateX(50px);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
    &.mfp-bg {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
  }
}

.mfp-move-from-top {
  .mfp-content {
    vertical-align: top;
  }
  .mfp-with-anim {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    transition: 0.2s;
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  &.mfp-bg {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    transition: 0.2s;
  }
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    &.mfp-bg {
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
    }
  }
  &.mfp-removing {
    .mfp-with-anim {
      -webkit-transform: translateY(-50px);
      -moz-transform: translateY(-50px);
      -o-transform: translateY(-50px);
      -ms-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
    &.mfp-bg {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
  }
}

.mfp-3d-unfold {
  .mfp-content {
    -webkit-perspective: 2000px;
    -moz-perspective: 2000px;
    -ms-perspective: 2000px;
    perspective: 2000px;
  }
  .mfp-with-anim {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateY(-60deg);
    -moz-transform: rotateY(-60deg);
    -o-transform: rotateY(-60deg);
    -ms-transform: rotateY(-60deg);
    transform: rotateY(-60deg);
  }
  &.mfp-bg {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
  }
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    &.mfp-bg {
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
    }
  }
  &.mfp-removing .mfp-with-anim {
    -webkit-transform: rotateY(60deg);
    -moz-transform: rotateY(60deg);
    -o-transform: rotateY(60deg);
    -ms-transform: rotateY(60deg);
    transform: rotateY(60deg);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
  }
  &.mfp-bg {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
  }
}

.mfp-zoom-out {
  .mfp-with-anim {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }
  &.mfp-bg {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
    -webkit-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    -ms-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
  }
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity = 80);
    }
  }
  &.mfp-removing {
    .mfp-with-anim {
      -webkit-transform: scale(1.3);
      -moz-transform: scale(1.3);
      -o-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
    &.mfp-bg {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
    }
  }
}

.mfp-dialog {
  background: #fff;
  padding: 20px 30px;
  text-align: left;
  max-width: 400px;
  margin: 40px auto;
  position: relative;
}

.mfp-search-dialog {
  max-width: 800px;
}

.tweet-list {
  list-style: none;
  margin: 0;
  padding: 0;
}