
@media (min-width: 992px) {
  body.boxed .global-wrap {
    width: 1230px;
    margin: 30px auto;
    -webkit-box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
  }
}

.global-wrap {
  background: #fff;
  height: 100%;
}

.dis-table {
  display: table;
}

.full, .full-page {
  width: 100%;
  height: 100%;
}

@media (max-width: 992px) {
  .full-page {
    min-height: 1000px;
  }
}

.rel {
  position: relative;
}

.full-height {
  height: 100%;
}

.full-page {
  position: relative;
}

.top-area {
  height: 400px;
  position: relative;
  overflow: hidden;
}

.special-area {
  position: relative;
  overflow: hidden;
  height: 500px;
}

@media (max-width: 992px) {
  .top-area, .special-area {
    height: auto;
  }
}
