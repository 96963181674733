
.fontawesome-icon-list .fa-hover {
  margin-bottom: 10px;
  > a {
    color: #737373;
    font-size: 11px;
    .fa {
      color: #515151;
      width: 20px;
      text-align: center;
      margin-right: 7px;
      font-size: 14px;
      position: relative;
    }
  }
}

.demo-grid {
  .row {
    margin-bottom: 20px;
    [class^="col-"] > div {
      height: 20px;
      background: #999;
    }
  }
  h5 {
    font-size: 14px;
    margin-bottom: 3px;
    color: #888;
  }
}