
.fotorama__html, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.fotorama--fullscreen, .fotorama__img {
  max-width: 99999px !important;
  max-height: 99999px !important;
  min-width: 0 !important;
  min-height: 0 !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.fotorama__wrap .fotorama__grab {
  cursor: grab;
}

.fotorama__grabbing * {
  cursor: grabbing;
}

.fotorama__img, .fotorama__spinner {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
}

.fotorama__img {
  margin: -50% 0 0 -50%;
  width: 100%;
  height: 100%;
}

.fotorama__wrap--css3 {
  .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__nav__shaft, .fotorama__stage__shaft, .fotorama__thumb-border, .fotorama__video-close, .fotorama__video-play {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fotorama__caption {
  -webkit-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.fotorama__nav {
  &:after, &:before {
    -webkit-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fotorama__stage {
  &:after, &:before {
    -webkit-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fotorama__wrap--css3 {
  .fotorama__html, .fotorama__nav, .fotorama__spinner {
    -webkit-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .fotorama__stage {
    -webkit-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    .fotorama__img {
      -webkit-transform: translateZ(0);
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -o-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  .fotorama__stage__frame {
    -webkit-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fotorama__wrap--video {
  .fotorama__stage {
    -webkit-transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
  }
  .fotorama__stage__frame--video {
    -webkit-transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    .fotorama__html, .fotorama__img {
      -webkit-transform: none !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -o-transform: none !important;
      -ms-transform: none !important;
      transform: none !important;
    }
  }
  .fotorama__stage__shaft {
    -webkit-transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
  }
}

.fotorama__wrap--css3 {
  .fotorama__nav__shaft, .fotorama__stage__shaft, .fotorama__thumb-border {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
    -webkit-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
    -webkit-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
    -moz-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
    -o-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
    -ms-transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
    transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
    -webkit-transition-duration: 0ms;
    -webkit-transition-duration: 0ms;
    -moz-transition-duration: 0ms;
    -o-transition-duration: 0ms;
    -ms-transition-duration: 0ms;
    transition-duration: 0ms;
  }
}

.fotorama__arr, .fotorama__fullscreen-icon, .fotorama__no-select, .fotorama__video-close, .fotorama__video-play, .fotorama__wrap {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fotorama__select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.fotorama__nav, .fotorama__nav__frame {
  margin: 0;
  padding: 0;
}

.fotorama__caption__wrap, .fotorama__nav__frame, .fotorama__nav__shaft {
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *display: inline;
  *zoom: 1;
}

.fotorama__wrap * {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.fotorama__caption__wrap {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fotorama--hidden, .fotorama__load {
  position: absolute;
  left: -99999px;
  top: -99999px;
  z-index: -1;
}

.fotorama__arr, .fotorama__fullscreen-icon, .fotorama__nav, .fotorama__nav__frame, .fotorama__nav__shaft, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video-close, .fotorama__video-play {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.fotorama__arr:before, .fotorama__fullscreen-icon:before, .fotorama__video-close:before, .fotorama__video-play:before {
  font-family: 'FontAwesome';
}

.fotorama__thumb {
  background-color: rgba(127, 127, 127, 0.2);
}

.fotorama {
  min-width: 1px;
  overflow: hidden;
  &:not(.fotorama--unobtrusive) > :not(:first-child) {
    display: none;
  }
}

.fullscreen {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  background: #000;
}

.fotorama--fullscreen {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  float: none !important;
  z-index: 2147483647 !important;
  background: #000;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  .fotorama__nav, .fotorama__stage {
    background: #000;
  }
}

.fotorama__wrap {
  -webkit-text-size-adjust: 100%;
  position: relative;
  direction: ltr;
}

.fotorama__wrap--rtl .fotorama__stage__frame {
  direction: rtl;
}

.fotorama__nav, .fotorama__stage {
  overflow: hidden;
  position: relative;
  max-width: 100%;
}

.fotorama__wrap--pan-y {
  -ms-touch-action: pan-y;
}

.fotorama__wrap .fotorama__pointer {
  cursor: pointer;
}

.fotorama__wrap--slide .fotorama__stage__frame {
  opacity: 1 !important;
  -ms-filter: none;
  filter: none;
}

.fotorama__stage__frame {
  overflow: hidden;
  &.fotorama__active {
    z-index: 8;
  }
}

.fotorama__wrap--fade {
  .fotorama__stage__frame {
    display: none;
  }
  .fotorama__fade-front, .fotorama__fade-rear, .fotorama__stage__frame.fotorama__active {
    display: block;
    left: 0;
    top: 0;
  }
  .fotorama__fade-front {
    z-index: 8;
  }
  .fotorama__fade-rear {
    z-index: 7;
    &.fotorama__active {
      z-index: 9;
    }
  }
  .fotorama__stage .fotorama__shadow {
    display: none;
  }
}

.fotorama__img {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity = 0);
  border: none !important;
}

.fotorama__error .fotorama__img, .fotorama__loaded .fotorama__img {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}

.fotorama--fullscreen .fotorama__loaded--full .fotorama__img, .fotorama__img--full {
  display: none;
}

.fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
  display: block;
}

.fotorama__wrap--only-active {
  .fotorama__nav, .fotorama__stage {
    max-width: 99999px !important;
  }
  .fotorama__stage__frame {
    visibility: hidden;
    &.fotorama__active {
      visibility: visible;
    }
  }
}

.fotorama__nav {
  font-size: 0;
  line-height: 0;
  text-align: center;
  display: none;
  white-space: nowrap;
  z-index: 5;
}

.fotorama__nav__shaft {
  position: relative;
  left: 0;
  top: 0;
  text-align: left;
}

.fotorama__nav__frame {
  position: relative;
  cursor: pointer;
}

.fotorama__nav--dots {
  display: block;
  position: absolute;
  bottom: 0;
  .fotorama__nav__frame {
    width: 18px;
    height: 30px;
  }
  .fotorama__nav__frame--thumb, .fotorama__thumb-border {
    display: none;
  }
}

.fotorama__nav--thumbs {
  display: block;
  .fotorama__nav__frame {
    padding-left: 0 !important;
    &:last-child {
      padding-right: 0 !important;
    }
  }
  .fotorama__nav__frame--dot {
    display: none;
  }
}

.fotorama__dot {
  display: block;
  width: 6px;
  height: 6px;
  position: relative;
  top: 12px;
  left: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #fff;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity = 50);
}

.fotorama__nav__frame.fotorama__active {
  pointer-events: none;
  cursor: default;
  .fotorama__dot {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}

.fotorama__active .fotorama__dot {
  background-color: #fff;
}

.fotorama__thumb {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.fotorama__thumb-border {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  border-style: solid;
  border-color: #ed8323;
}

.fotorama__caption {
  position: absolute;
  z-index: 12;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  a {
    text-decoration: none;
    color: #000;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.5);
    &:hover {
      color: #333;
      border-color: rgba(51, 51, 51, 0.5);
    }
  }
}

.fotorama__wrap--rtl .fotorama__caption {
  left: auto;
  right: 0;
}

.fotorama__wrap--no-captions .fotorama__caption, .fotorama__wrap--video .fotorama__caption {
  display: none;
}

.fotorama__caption__wrap {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px 10px;
}

.fotorama__wrap--css3 {
  .fotorama__spinner {
    -webkit-animation: spinner 24s infinite linear;
    -webkit-animation: spinner 24s infinite linear;
    -moz-animation: spinner 24s infinite linear;
    -o-animation: spinner 24s infinite linear;
    -ms-animation: spinner 24s infinite linear;
    animation: spinner 24s infinite linear;
  }
  .fotorama__html, .fotorama__stage .fotorama__img {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    -ms-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
}

.fotorama__wrap--video .fotorama__stage__frame--video {
  .fotorama__html, .fotorama__img {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
  }
}

.fotorama__select {
  cursor: auto;
}

.fotorama__video {
  top: 32px;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 10;
}

.fotorama__arr, .fotorama__fullscreen-icon, .fotorama__video-close, .fotorama__video-play {
  position: absolute;
  z-index: 11;
  cursor: pointer;
}

.fotorama__arr {
  text-align: center;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  line-height: 32px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  margin: -16px 10px 0 10px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 20px;
  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
}

.fotorama__arr--prev {
  left: 0;
  &:before {
    content: '\f104';
  }
}

.fotorama__arr--next {
  right: 0;
  &:before {
    content: '\f105';
  }
}

.fotorama__arr--disabled {
  pointer-events: none;
  cursor: default;
  *display: none;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity = 30);
}

.fotorama__fullscreen-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  top: 0;
  right: 0;
  z-index: 20;
  color: #fff;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity = 80);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: 10px;
  &:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  &:before {
    content: '\f065';
  }
}

.fotorama--fullscreen .fotorama__fullscreen-icon:before {
  content: '\f066';
}

.fotorama__video-play {
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  margin-left: -48px;
  margin-top: -48px;
  background-position: 0 -64px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity = 0);
}

.fotorama__wrap--css2 .fotorama__video-play, .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
  display: none;
}

.fotorama__error .fotorama__video-play, .fotorama__loaded .fotorama__video-play {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  display: block;
}

.fotorama__nav__frame .fotorama__video-play {
  width: 32px;
  height: 32px;
  margin-left: -16px;
  margin-top: -16px;
  background-position: -64px -32px;
}

.fotorama__video-close {
  width: 32px;
  height: 32px;
  top: 0;
  right: 0;
  background-position: -64px 0;
  z-index: 20;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity = 0);
}

.fotorama__wrap--css2 .fotorama__video-close {
  display: none;
}

.fotorama__wrap--css3 .fotorama__video-close {
  -webkit-transform: translate3d(32px, -32px, 0);
  -moz-transform: translate3d(32px, -32px, 0);
  -o-transform: translate3d(32px, -32px, 0);
  -ms-transform: translate3d(32px, -32px, 0);
  transform: translate3d(32px, -32px, 0);
}

.fotorama__wrap--video .fotorama__video-close {
  display: block;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__video-close {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows {
  .fotorama__arr, .fotorama__fullscreen-icon {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
  }
}

.fotorama__wrap--video {
  .fotorama__arr, .fotorama__fullscreen-icon {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity = 0);
  }
}

.fotorama__wrap--css2 {
  &.fotorama__wrap--no-controls {
    .fotorama__arr, .fotorama__fullscreen-icon {
      display: none;
    }
  }
  &.fotorama__wrap--video {
    .fotorama__arr, .fotorama__fullscreen-icon {
      display: none;
    }
  }
}

.fotorama__wrap--css3 {
  &.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon, &.fotorama__wrap--video .fotorama__fullscreen-icon {
    -webkit-transform: translate3d(32px, -32px, 0);
    -moz-transform: translate3d(32px, -32px, 0);
    -o-transform: translate3d(32px, -32px, 0);
    -ms-transform: translate3d(32px, -32px, 0);
    transform: translate3d(32px, -32px, 0);
  }
  &.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev, &.fotorama__wrap--video .fotorama__arr--prev {
    -webkit-transform: translate3d(-48px, 0, 0);
    -moz-transform: translate3d(-48px, 0, 0);
    -o-transform: translate3d(-48px, 0, 0);
    -ms-transform: translate3d(-48px, 0, 0);
    transform: translate3d(-48px, 0, 0);
  }
  &.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next, &.fotorama__wrap--video .fotorama__arr--next {
    -webkit-transform: translate3d(48px, 0, 0);
    -moz-transform: translate3d(48px, 0, 0);
    -o-transform: translate3d(48px, 0, 0);
    -ms-transform: translate3d(48px, 0, 0);
    transform: translate3d(48px, 0, 0);
  }
  .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__video-close, .fotorama__video-play {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
    transition-property: transform, opacity;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
}

.fotorama__nav {
  &:after, &:before {
    content: "";
    display: block;
    position: absolute;
    text-decoration: none;
    top: 0;
    bottom: 0;
    width: 10px;
    height: auto;
    z-index: 10;
    pointer-events: none;
    background-repeat: no-repeat;
    -webkit-background-size: 1px 100%, 5px 100%;
    -moz-background-size: 1px 100%, 5px 100%;
    background-size: 1px 100%, 5px 100%;
  }
}

.fotorama__stage {
  &:after, &:before {
    content: "";
    display: block;
    position: absolute;
    text-decoration: none;
    top: 0;
    bottom: 0;
    width: 10px;
    height: auto;
    z-index: 10;
    pointer-events: none;
    background-repeat: no-repeat;
    -webkit-background-size: 1px 100%, 5px 100%;
    -moz-background-size: 1px 100%, 5px 100%;
    background-size: 1px 100%, 5px 100%;
  }
}

.fotorama__nav:before, .fotorama__stage:before {
  background-position: 0 0, 0 0;
  left: -10px;
}

.fotorama__nav.fotorama__shadows--left:before, .fotorama__stage.fotorama__shadows--left:before {
  left: 0;
}

.fotorama__nav:after, .fotorama__stage:after {
  background-position: 100% 0, 100% 0;
  right: -10px;
}

.fotorama__nav.fotorama__shadows--right:after, .fotorama__stage.fotorama__shadows--right:after {
  right: 0;
}

.fotorama--fullscreen {
  .fotorama__nav {
    &:after, &:before {
      display: none;
    }
  }
  .fotorama__stage {
    &:after, &:before {
      display: none;
    }
  }
}

.fotorama__wrap--fade .fotorama__stage {
  &:after, &:before {
    display: none;
  }
}

.fotorama__wrap--no-shadows {
  .fotorama__nav {
    &:after, &:before {
      display: none;
    }
  }
  .fotorama__stage {
    &:after, &:before {
      display: none;
    }
  }
}