.datepicker {
  padding: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 250px;
  border: 1px solid #ccc;
  padding: 10px 15px;
}

.datepicker.datepicker-rtl {
  direction: rtl;
  table tr td span {
    float: right;
  }
}

.datepicker-dropdown {
  top: 0;
  left: 0;
  &:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
  }
  &:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
  }
  &.datepicker-orient-left {
    &:before {
      left: 6px;
    }
    &:after {
      left: 7px;
    }
  }
  &.datepicker-orient-right {
    &:before {
      right: 6px;
    }
    &:after {
      right: 7px;
    }
  }
  &.datepicker-orient-top {
    &:before {
      top: -7px;
    }
    &:after {
      top: -6px;
    }
  }
  &.datepicker-orient-bottom {
    &:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid #999;
    }
    &:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #fff;
    }
  }
}

.datepicker {
  > div {
    display: none;
  }
  &.days div.datepicker-days, &.months div.datepicker-months, &.years div.datepicker-years {
    display: block;
  }
  table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    tr {
      td, th {
        text-align: center;
        width: 30px;
        height: 30px;
        border: none;
      }
    }
  }
}

.table-striped .datepicker table tr {
  td, th {
    background-color: transparent;
  }
}

.datepicker table tr td {
  &.day {
    &:hover, &.focused {
      background: #eee;
      cursor: pointer;
    }
    border: 1px solid #e6e6e6;
  }
  &.old, &.new {
    color: #999;
  }
  &.disabled {
    background: none;
    color: #999;
    cursor: default;
    &:hover {
      background: none;
      color: #999;
      cursor: default;
    }
  }
  &.today {
    color: #000;
    position: relative;
    &:hover {
      color: #000;
      position: relative;
    }
    &.disabled {
      color: #000;
      position: relative;
      &:hover {
        color: #000;
        position: relative;
      }
    }
    &:before, &:hover:before {
      content: '';
      bottom: 2px;
      right: 2px;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 7px 7px;
      border-color: transparent transparent #ed8323 transparent;
      position: absolute;
    }
    &.disabled {
      &:before, &:hover:before {
        content: '';
        bottom: 2px;
        right: 2px;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 7px 7px;
        border-color: transparent transparent #ed8323 transparent;
        position: absolute;
      }
    }
    &:active, &:hover:active {
      background-image: none;
    }
    &.disabled {
      &:active, &:hover:active {
        background-image: none;
      }
    }
    &.active, &:hover.active {
      background-image: none;
    }
    &.disabled {
      &.active, &:hover.active {
        background-image: none;
      }
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.today {
  background-image: none;
  &:hover {
    background-image: none;
  }
  &.disabled {
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.datepicker table tr td.today {
  &.disabled, &:hover.disabled {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &.disabled, &:hover.disabled {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
  &[disabled], &:hover[disabled] {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &[disabled], &:hover[disabled] {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

fieldset[disabled] .datepicker table tr td.today {
  background-color: #ffdb99;
  border-color: #ffb733;
  &:hover {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    background-color: #ffdb99;
    border-color: #ffb733;
    &:hover {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

.datepicker table tr td.today {
  &.disabled:hover, &[disabled]:hover, &.disabled[disabled]:hover {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
}

fieldset[disabled] .datepicker table tr td.today {
  &:hover, &.disabled:hover {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
}

.datepicker table tr td.today {
  &.disabled:focus, &:hover.disabled:focus {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &.disabled:focus, &:hover.disabled:focus {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
  &[disabled]:focus, &:hover[disabled]:focus {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &[disabled]:focus, &:hover[disabled]:focus {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

fieldset[disabled] .datepicker table tr td.today {
  &:focus, &:hover:focus {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

.datepicker table tr td.today {
  &.disabled:active, &:hover.disabled:active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &.disabled:active, &:hover.disabled:active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
  &[disabled]:active, &:hover[disabled]:active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &[disabled]:active, &:hover[disabled]:active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

fieldset[disabled] .datepicker table tr td.today {
  &:active, &:hover:active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

.datepicker table tr td.today {
  &.disabled.active, &:hover.disabled.active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &.disabled.active, &:hover.disabled.active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
  &[disabled].active, &:hover[disabled].active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &[disabled].active, &:hover[disabled].active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

fieldset[disabled] .datepicker table tr td.today {
  &.active, &:hover.active {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

.datepicker table tr td {
  &.today {
    &:hover:hover {
      color: #000;
    }
    &.active:hover {
      color: #fff;
    }
  }
  &.range {
    background: #eee;
    -webkit-border-radius: 0;
    border-radius: 0;
    &:hover {
      background: #eee;
      -webkit-border-radius: 0;
      border-radius: 0;
    }
    &.disabled {
      background: #eee;
      -webkit-border-radius: 0;
      border-radius: 0;
      &:hover {
        background: #eee;
        -webkit-border-radius: 0;
        border-radius: 0;
      }
    }
    &.today {
      color: #000;
      background-color: #f7ca77;
      border-color: #f1a417;
      -webkit-border-radius: 0;
      border-radius: 0;
      &:hover {
        color: #000;
        background-color: #f7ca77;
        border-color: #f1a417;
        -webkit-border-radius: 0;
        border-radius: 0;
      }
      &.disabled {
        color: #000;
        background-color: #f7ca77;
        border-color: #f1a417;
        -webkit-border-radius: 0;
        border-radius: 0;
        &:hover {
          color: #000;
          background-color: #f7ca77;
          border-color: #f1a417;
          -webkit-border-radius: 0;
          border-radius: 0;
        }
      }
      &:hover, &.disabled:hover, &:focus, &:hover:focus {
        color: #000;
        background-color: #f4bb51;
        border-color: #bf800c;
      }
      &.disabled {
        &:focus, &:hover:focus {
          color: #000;
          background-color: #f4bb51;
          border-color: #bf800c;
        }
      }
      &:active, &:hover:active {
        color: #000;
        background-color: #f4bb51;
        border-color: #bf800c;
      }
      &.disabled {
        &:active, &:hover:active {
          color: #000;
          background-color: #f4bb51;
          border-color: #bf800c;
        }
      }
      &.active, &:hover.active {
        color: #000;
        background-color: #f4bb51;
        border-color: #bf800c;
      }
      &.disabled {
        &.active, &:hover.active {
          color: #000;
          background-color: #f4bb51;
          border-color: #bf800c;
        }
      }
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.range.today {
  color: #000;
  background-color: #f4bb51;
  border-color: #bf800c;
  &:hover {
    color: #000;
    background-color: #f4bb51;
    border-color: #bf800c;
  }
  &.disabled {
    color: #000;
    background-color: #f4bb51;
    border-color: #bf800c;
    &:hover {
      color: #000;
      background-color: #f4bb51;
      border-color: #bf800c;
    }
  }
}

.datepicker table tr td.range.today {
  &:active, &:hover:active {
    background-image: none;
  }
  &.disabled {
    &:active, &:hover:active {
      background-image: none;
    }
  }
  &.active, &:hover.active {
    background-image: none;
  }
  &.disabled {
    &.active, &:hover.active {
      background-image: none;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.range.today {
  background-image: none;
  &:hover {
    background-image: none;
  }
  &.disabled {
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.datepicker table tr td.range.today {
  &.disabled, &:hover.disabled {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &.disabled, &:hover.disabled {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
  &[disabled], &:hover[disabled] {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &[disabled], &:hover[disabled] {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range.today {
  background-color: #f7ca77;
  border-color: #f1a417;
  &:hover {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    background-color: #f7ca77;
    border-color: #f1a417;
    &:hover {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

.datepicker table tr td.range.today {
  &.disabled:hover, &[disabled]:hover, &.disabled[disabled]:hover {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
}

fieldset[disabled] .datepicker table tr td.range.today {
  &:hover, &.disabled:hover {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
}

.datepicker table tr td.range.today {
  &.disabled:focus, &:hover.disabled:focus {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &.disabled:focus, &:hover.disabled:focus {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
  &[disabled]:focus, &:hover[disabled]:focus {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &[disabled]:focus, &:hover[disabled]:focus {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range.today {
  &:focus, &:hover:focus {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

.datepicker table tr td.range.today {
  &.disabled:active, &:hover.disabled:active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &.disabled:active, &:hover.disabled:active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
  &[disabled]:active, &:hover[disabled]:active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &[disabled]:active, &:hover[disabled]:active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range.today {
  &:active, &:hover:active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

.datepicker table tr td.range.today {
  &.disabled.active, &:hover.disabled.active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &.disabled.active, &:hover.disabled.active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
  &[disabled].active, &:hover[disabled].active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &[disabled].active, &:hover[disabled].active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range.today {
  &.active, &:hover.active {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

.datepicker table tr td.selected {
  color: #fff;
  background-color: #999;
  border-color: #555;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #fff;
    background-color: #999;
    border-color: #555;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  &.disabled {
    color: #fff;
    background-color: #999;
    border-color: #555;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      color: #fff;
      background-color: #999;
      border-color: #555;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
  }
  &:hover, &.disabled:hover, &:focus, &:hover:focus {
    border-color: #373737;
  }
  &.disabled {
    &:focus, &:hover:focus {
      border-color: #373737;
    }
  }
  &:active, &:hover:active {
    border-color: #373737;
  }
  &.disabled {
    &:active, &:hover:active {
      border-color: #373737;
    }
  }
  &.active, &:hover.active {
    border-color: #373737;
  }
  &.disabled {
    &.active, &:hover.active {
      border-color: #373737;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.selected {
  border-color: #373737;
  &:hover {
    border-color: #373737;
  }
  &.disabled {
    border-color: #373737;
    &:hover {
      border-color: #373737;
    }
  }
}

.datepicker table tr td.selected {
  &:hover, &.disabled:hover, &:focus, &:hover:focus {
    background-color: #858585;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #858585;
    }
  }
  &:active, &:hover:active {
    background-color: #858585;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #858585;
    }
  }
  &.active, &:hover.active {
    background-color: #858585;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #858585;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.selected {
  background-color: #858585;
  &:hover {
    background-color: #858585;
  }
  &.disabled {
    background-color: #858585;
    &:hover {
      background-color: #858585;
    }
  }
}

.datepicker table tr td.selected {
  &:active, &:hover:active {
    background-image: none;
  }
  &.disabled {
    &:active, &:hover:active {
      background-image: none;
    }
  }
  &.active, &:hover.active {
    background-image: none;
  }
  &.disabled {
    &.active, &:hover.active {
      background-image: none;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.selected {
  background-image: none;
  &:hover {
    background-image: none;
  }
  &.disabled {
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.datepicker table tr td.selected {
  &.disabled, &:hover.disabled {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &.disabled, &:hover.disabled {
      background-color: #999;
      border-color: #555;
    }
  }
  &[disabled], &:hover[disabled] {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &[disabled], &:hover[disabled] {
      background-color: #999;
      border-color: #555;
    }
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  background-color: #999;
  border-color: #555;
  &:hover {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    background-color: #999;
    border-color: #555;
    &:hover {
      background-color: #999;
      border-color: #555;
    }
  }
}

.datepicker table tr td.selected {
  &.disabled:hover, &[disabled]:hover, &.disabled[disabled]:hover {
    background-color: #999;
    border-color: #555;
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &:hover, &.disabled:hover {
    background-color: #999;
    border-color: #555;
  }
}

.datepicker table tr td.selected {
  &.disabled:focus, &:hover.disabled:focus {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &.disabled:focus, &:hover.disabled:focus {
      background-color: #999;
      border-color: #555;
    }
  }
  &[disabled]:focus, &:hover[disabled]:focus {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &[disabled]:focus, &:hover[disabled]:focus {
      background-color: #999;
      border-color: #555;
    }
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &:focus, &:hover:focus {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #999;
      border-color: #555;
    }
  }
}

.datepicker table tr td.selected {
  &.disabled:active, &:hover.disabled:active {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &.disabled:active, &:hover.disabled:active {
      background-color: #999;
      border-color: #555;
    }
  }
  &[disabled]:active, &:hover[disabled]:active {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &[disabled]:active, &:hover[disabled]:active {
      background-color: #999;
      border-color: #555;
    }
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &:active, &:hover:active {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #999;
      border-color: #555;
    }
  }
}

.datepicker table tr td.selected {
  &.disabled.active, &:hover.disabled.active {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &.disabled.active, &:hover.disabled.active {
      background-color: #999;
      border-color: #555;
    }
  }
  &[disabled].active, &:hover[disabled].active {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &[disabled].active, &:hover[disabled].active {
      background-color: #999;
      border-color: #555;
    }
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &.active, &:hover.active {
    background-color: #999;
    border-color: #555;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #999;
      border-color: #555;
    }
  }
}

.datepicker table tr td.active {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  &.disabled {
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      color: #fff;
      background-color: #428bca;
      border-color: #357ebd;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
  }
  &:hover, &.disabled:hover, &:focus, &:hover:focus {
    color: #fff;
    background-color: #ed8323;
    border-color: #e27513;
  }
  &.disabled {
    &:focus, &:hover:focus {
      color: #fff;
      background-color: #ed8323;
      border-color: #e27513;
    }
  }
  &:active, &:hover:active {
    color: #fff;
    background-color: #ed8323;
    border-color: #e27513;
  }
  &.disabled {
    &:active, &:hover:active {
      color: #fff;
      background-color: #ed8323;
      border-color: #e27513;
    }
  }
  &.active, &:hover.active {
    color: #fff;
    background-color: #ed8323;
    border-color: #e27513;
  }
  &.disabled {
    &.active, &:hover.active {
      color: #fff;
      background-color: #ed8323;
      border-color: #e27513;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.active {
  color: #fff;
  background-color: #ed8323;
  border-color: #e27513;
  &:hover {
    color: #fff;
    background-color: #ed8323;
    border-color: #e27513;
  }
  &.disabled {
    color: #fff;
    background-color: #ed8323;
    border-color: #e27513;
    &:hover {
      color: #fff;
      background-color: #ed8323;
      border-color: #e27513;
    }
  }
}

.datepicker table tr td.active {
  &:active, &:hover:active {
    background-image: none;
  }
  &.disabled {
    &:active, &:hover:active {
      background-image: none;
    }
  }
  &.active, &:hover.active {
    background-image: none;
  }
  &.disabled {
    &.active, &:hover.active {
      background-image: none;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td.active {
  background-image: none;
  &:hover {
    background-image: none;
  }
  &.disabled {
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.datepicker table tr td.active {
  &.disabled, &:hover.disabled {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled, &:hover.disabled {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled], &:hover[disabled] {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled], &:hover[disabled] {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td.active {
  background-color: #428bca;
  border-color: #357ebd;
  &:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    background-color: #428bca;
    border-color: #357ebd;
    &:hover {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td.active {
  &.disabled:hover, &[disabled]:hover, &.disabled[disabled]:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &:hover, &.disabled:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

.datepicker table tr td.active {
  &.disabled:focus, &:hover.disabled:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled:focus, &:hover.disabled:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled]:focus, &:hover[disabled]:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled]:focus, &:hover[disabled]:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &:focus, &:hover:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td.active {
  &.disabled:active, &:hover.disabled:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled:active, &:hover.disabled:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled]:active, &:hover[disabled]:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled]:active, &:hover[disabled]:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &:active, &:hover:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td.active {
  &.disabled.active, &:hover.disabled.active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled.active, &:hover.disabled.active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled].active, &:hover[disabled].active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled].active, &:hover[disabled].active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &.active, &:hover.active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
  &.disabled {
    background: none;
    color: #999;
    cursor: default;
    &:hover {
      background: none;
      color: #999;
      cursor: default;
    }
  }
  &.active {
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      color: #fff;
      background-color: #428bca;
      border-color: #357ebd;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
    &.disabled {
      color: #fff;
      background-color: #428bca;
      border-color: #357ebd;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      &:hover {
        color: #fff;
        background-color: #428bca;
        border-color: #357ebd;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }
    }
    &:hover, &.disabled:hover, &:focus, &:hover:focus {
      color: #fff;
      background-color: #ed8323;
      border-color: #e27513;
    }
    &.disabled {
      &:focus, &:hover:focus {
        color: #fff;
        background-color: #ed8323;
        border-color: #e27513;
      }
    }
    &:active, &:hover:active {
      color: #fff;
      background-color: #ed8323;
      border-color: #e27513;
    }
    &.disabled {
      &:active, &:hover:active {
        color: #fff;
        background-color: #ed8323;
        border-color: #e27513;
      }
    }
    &.active, &:hover.active {
      color: #fff;
      background-color: #ed8323;
      border-color: #e27513;
    }
    &.disabled {
      &.active, &:hover.active {
        color: #fff;
        background-color: #ed8323;
        border-color: #e27513;
      }
    }
  }
}

.open .dropdown-toggle.datepicker table tr td span.active {
  color: #fff;
  background-color: #ed8323;
  border-color: #e27513;
  &:hover {
    color: #fff;
    background-color: #ed8323;
    border-color: #e27513;
  }
  &.disabled {
    color: #fff;
    background-color: #ed8323;
    border-color: #e27513;
    &:hover {
      color: #fff;
      background-color: #ed8323;
      border-color: #e27513;
    }
  }
}

.datepicker table tr td span.active {
  &:active, &:hover:active {
    background-image: none;
  }
  &.disabled {
    &:active, &:hover:active {
      background-image: none;
    }
  }
  &.active, &:hover.active {
    background-image: none;
  }
  &.disabled {
    &.active, &:hover.active {
      background-image: none;
    }
  }
}

.open .dropdown-toggle.datepicker table tr td span.active {
  background-image: none;
  &:hover {
    background-image: none;
  }
  &.disabled {
    background-image: none;
    &:hover {
      background-image: none;
    }
  }
}

.datepicker table tr td span.active {
  &.disabled, &:hover.disabled {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled, &:hover.disabled {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled], &:hover[disabled] {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled], &:hover[disabled] {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  background-color: #428bca;
  border-color: #357ebd;
  &:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    background-color: #428bca;
    border-color: #357ebd;
    &:hover {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td span.active {
  &.disabled:hover, &[disabled]:hover, &.disabled[disabled]:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &:hover, &.disabled:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

.datepicker table tr td span.active {
  &.disabled:focus, &:hover.disabled:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled:focus, &:hover.disabled:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled]:focus, &:hover[disabled]:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled]:focus, &:hover[disabled]:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &:focus, &:hover:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &:focus, &:hover:focus {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td span.active {
  &.disabled:active, &:hover.disabled:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled:active, &:hover.disabled:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled]:active, &:hover[disabled]:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled]:active, &:hover[disabled]:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &:active, &:hover:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &:active, &:hover:active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker table tr td span.active {
  &.disabled.active, &:hover.disabled.active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.disabled.active, &:hover.disabled.active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
  &[disabled].active, &:hover[disabled].active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &[disabled].active, &:hover[disabled].active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &.active, &:hover.active {
    background-color: #428bca;
    border-color: #357ebd;
  }
  &.disabled {
    &.active, &:hover.active {
      background-color: #428bca;
      border-color: #357ebd;
    }
  }
}

.datepicker {
  table tr td span {
    &.old, &.new {
      color: #999;
    }
  }
  th.datepicker-switch {
    width: 145px;
  }
  thead tr:first-child th, tfoot tr th {
    cursor: pointer;
  }
  thead tr:first-child th:hover, tfoot tr th:hover {
    background: #eee;
  }
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
  thead tr:first-child th.cw {
    cursor: default;
    background-color: transparent;
  }
}

.input-group.date .input-group-addon i {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.input-daterange .input-group-addon {
  width: auto;
  min-width: 16px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: solid #ccc;
  border-width: 1px 0;
  margin-left: -5px;
  margin-right: -5px;
}

.datepicker {
  &.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    color: #333;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.428571429;
    -webkit-box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
    padding: 7px 10px;
    th {
      padding: 0px 5px;
    }
  }
  &.datepicker-inline th, &.dropdown-menu td, &.datepicker-inline td {
    padding: 0px 5px;
  }
  thead tr:first-child th {
    &.prev:before, &.next:before {
      font-family: 'FontAwesome';
      content: '\f105';
      font-size: 18px;
    }
    &.prev:before {
      content: '\f104';
    }
  }
}

.bootstrap-timepicker {
  position: relative;
  &.pull-right .bootstrap-timepicker-widget.dropdown-menu {
    left: auto;
    right: 0;
    &:before {
      left: auto;
      right: 12px;
    }
    &:after {
      left: auto;
      right: 13px;
    }
  }
  .add-on {
    cursor: pointer;
    i {
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}

.bootstrap-timepicker-widget {
  &.dropdown-menu {
    padding: 7px 10px;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
    &.open {
      display: inline-block;
    }
    &:before {
      border-bottom: 7px solid rgba(0, 0, 0, 0.2);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      content: "";
      display: inline-block;
      position: absolute;
    }
    &:after {
      border-bottom: 6px solid #fff;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      content: "";
      display: inline-block;
      position: absolute;
    }
  }
  &.timepicker-orient-left {
    &:before {
      left: 6px;
    }
    &:after {
      left: 7px;
    }
  }
  &.timepicker-orient-right {
    &:before {
      right: 6px;
    }
    &:after {
      right: 7px;
    }
  }
  &.timepicker-orient-top {
    &:before {
      top: -7px;
    }
    &:after {
      top: -6px;
    }
  }
  &.timepicker-orient-bottom {
    &:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid #999;
    }
    &:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #fff;
    }
  }
  a.btn, input {
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  table {
    width: 100%;
    margin: 0;
    td {
      text-align: center;
      height: 30px;
      margin: 0;
      padding: 2px;
      &:not(.separator) {
        min-width: 30px;
      }
      span {
        width: 100%;
      }
      a {
        width: 100%;
        display: inline-block;
        margin: 0;
        outline: 0;
        color: #333;
        width: 35px;
        height: 35px;
        line-height: 35px;
        &:hover {
          text-decoration: none;
          background-color: #eee;
        }
        i {
          margin-top: 2px;
          font-size: 18px;
        }
      }
      input {
        width: 35px;
        margin: 0;
        text-align: center;
        color: #000;
      }
    }
  }
  .modal-content {
    padding: 4px;
  }
}

@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}

@media (max-width: 767px) {
  .bootstrap-timepicker {
    width: 100%;
    .dropdown-menu {
      width: 100%;
    }
  }
}