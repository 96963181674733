
.card-thumb {
  position: relative;
  height: 170px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #fafafa;
  padding: 15px 20px;
  border: 1px solid #e6e6e6;
  display: block;
  &.card-thumb-primary {
    border-color: #ed8323;
  }
  .card-thumb-primary-label {
    position: absolute;
    top: 10px;
    left: 10px;
    display: inline-block;
    line-height: 1em;
    padding: 4px 6px;
    background: #ed8323;
    color: #fff;
    font-size: 10px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
  .card-thumb-new {
    height: 50px;
    line-height: 50px;
    width: 50px;
    text-align: center;
    background: #ed8323;
    color: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 30px;
    float: left;
    margin-right: 10px;
    margin-top: 45px;
    margin-left: 35px;
    + p {
      margin-top: 55px;
      font-size: 12px;
    }
  }
  .card-thumb-type {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: auto;
  }
  .card-thumb-number {
    font-size: 16px;
    color: #5c5c5c;
    font-weight: 400;
    letter-spacing: 2px;
    margin-top: 30px;
    margin-bottom: 0;
  }
  .card-thumb-valid {
    font-size: 12px;
    color: #888;
    > span {
      font-size: 15px;
      color: #626262;
    }
  }
  .card-thumb-actions {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 7px;
    right: 10px;
    > li {
      display: inline-block;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
      > a {
        display: block;
        width: 23px;
        line-height: 23px;
        height: 23px;
        text-align: center;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 12px;
        -webkit-box-shadow: 0 0 0 1px #737373;
        box-shadow: 0 0 0 1px #737373;
        color: #737373;
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        filter: alpha(opacity = 50);
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -o-transition: 0.3s;
        -ms-transition: 0.3s;
        transition: 0.3s;
        &:hover {
          background: #ed8323;
          color: #fff;
          -webkit-box-shadow: 0 0 0 1px #d66f11;
          box-shadow: 0 0 0 1px #d66f11;
        }
      }
    }
  }
  &:hover .card-thumb-actions > li > a {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}