.testimonial {
  .testimonial-inner {
    background: #fff;
    padding: 20px;
    border: 1px solid #d9d9d9;
    position: relative;
    margin-bottom: 22px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      top: 100%;
    }
    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      top: 100%;
      border-color: #fff transparent transparent transparent;
      border-width: 18px 18px 0 0;
    }
    &:before {
      border-color: #d9d9d9 transparent transparent transparent;
      border-width: 20px 20px 0 0;
      left: 19px;
    }
  }
  blockquote {
    padding: 0;
    font-weight: 400;
    font-style: italic;
    margin: 0;
    color: #737373;
    &:before {
      display: none;
    }
    p {
      color: #737373;
    }
  }
  .testimonial-author {
    overflow: hidden;
    img {
      width: auto;
      float: left;
      margin-right: 10px;
      -webkit-border-radius: 50%;
      border-radius: 50%;
    }
    .testimonial-author-name {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 13px;
    }
    cite {
      font-size: 12px;
      font-style: italic;
      line-height: 1em;
      margin: 0;
      opacity: 0.85;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
      filter: alpha(opacity = 85);
      display: block;
    }
  }
}

.testimonial-color .testimonial-inner {
  border: 1px solid #ed8323;
  background: #ed8323;
  blockquote {
    color: #fff;
    p {
      color: #fff;
    }
  }
  &:after, &:before {
    border-color: #ed8323 transparent transparent transparent;
  }
}